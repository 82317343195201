import Datetime from 'utils/datetime/datetime'
import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { parseZoomResolution, ZoomResolution } from './zoomResolutionSchema'

export type OptimizeViewStore = {
  showOutcomeCheckbox: boolean
  zoomResolutionSwitch: ZoomResolution
  optimizedViewRange: [ISODateTime, ISODateTime]
}

export const DEFAULT_ZOOM_RESOLUTION_SWITCH: OptimizeViewStore['zoomResolutionSwitch'] = { type: `week` }

function initializeOptimizeViewStore(): OptimizeViewStore {
  const showOutcomeCheckbox = localStorage.getItem(`optimizeShowOutcomeCheckbox`) !== `false`
  const storedZoomResolutionSwitch = localStorage.getItem(`optimizeZoomResolutionSwitch`)
  const zoomResolutionSwitch = parseZoomResolution(storedZoomResolutionSwitch, DEFAULT_ZOOM_RESOLUTION_SWITCH)
  return {
    showOutcomeCheckbox,
    zoomResolutionSwitch,
    get optimizedViewRange() {
      return optimizeViewRange(this.showOutcomeCheckbox, this.zoomResolutionSwitch)
    },
  }
}

const optimizeViewStore = proxy<OptimizeViewStore>(initializeOptimizeViewStore())

subscribeKey(optimizeViewStore, `showOutcomeCheckbox`, (showOutcome) => {
  localStorage.setItem(`optimizeShowOutcomeCheckbox`, showOutcome ? `true` : `false`)
})

subscribeKey(optimizeViewStore, `zoomResolutionSwitch`, (zoomResolution) => {
  localStorage.setItem(`optimizeZoomResolutionSwitch`, JSON.stringify(zoomResolution))
})

export function optimizeViewRange(
  includeOutcome?: boolean,
  zoomResolution?: OptimizeViewStore['zoomResolutionSwitch']
): [ISODateTime, ISODateTime] {
  if (includeOutcome === undefined || zoomResolution === undefined) {
    const snap = snapshot(optimizeViewStore)
    includeOutcome = snap.showOutcomeCheckbox
    zoomResolution = snap.zoomResolutionSwitch
  }

  if (zoomResolution.type === `day` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(24)]
  }
  if (zoomResolution.type === `day` && includeOutcome) {
    return [Datetime.getISONow(-23), Datetime.getISONow(24)]
  }
  if (zoomResolution.type === `week` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(169)]
  }
  if (zoomResolution.type === `week` && includeOutcome) {
    return [Datetime.getISONow(-167), Datetime.getISONow(169)]
  }

  if (zoomResolution.type === 'custom') {
    return [zoomResolution.startTime, zoomResolution.endTime]
  }

  return [Datetime.getISONow(1), Datetime.getISONow(169)]
}

export default optimizeViewStore
