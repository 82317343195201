import i18n from 'i18next'
import authStore from 'store/auth/auth'
import elplanStore from 'store/elplan/elplan'
import DEFAULT_ALIASES, { DEFAULT_CLIENT_SIDE_ALIASES, OPTJOB_TYPE_ID_TO_NAME } from 'ui/uiConfig/template/aliases'
import DEFAULT_DATASET_TEMPLATES from 'ui/uiConfig/template/datasets'
import Datetime from 'utils/datetime/datetime'
import { extractOrganizationAndSystemFromLocality, prefixReturnIdsForDbSDatasetInstruction } from 'utils/dbs/dbs.helper'
import { snapshot } from 'valtio'

import { getReturnIdsFromDatasetInstruction } from 'helpers/dataset.helper/dataset.helper'
import {
  clone,
  applyToNestedString,
  applyToNestedStringForDictKeys,
  isObject,
  merge,
  applyToNestedKeysThatStartWith,
} from 'helpers/global.helper/global.helper'

export function getUiConfig(uiConfigs: UiConfigCollection, uid: number): UiConfig {
  return uiConfigs[uid]
}

export function getUiConfigById(uiConfigs: UiConfigCollection, id: number): UiConfig | null {
  for (const uid in uiConfigs) {
    if (uiConfigs[uid].id === id) {
      return uiConfigs[uid]
    }
  }

  return null
}

export function getUiconfigUidFromId(uiConfigs: UiConfigCollection, id: number): number | null {
  for (const uid in uiConfigs) {
    if (uiConfigs[uid].id === id) {
      return uiConfigs[uid].uid
    }
  }

  return null
}

function getLatestBidDeadlineDateTime(deadlineTime: `${number}:${number}:${number}`): ISODateTime {
  const todaysDeadline = new Date()
  const now = new Date()

  const [hours, minutes, seconds] = deadlineTime.split(':').map(Number)
  todaysDeadline.setHours(hours, minutes, seconds, 0)

  if (now < todaysDeadline) {
    // Return the same time but one day earlier if now is earlier than today's deadline
    const adjustedDate = new Date(todaysDeadline)
    adjustedDate.setDate(todaysDeadline.getDate() - 1)
    return adjustedDate.toISOString() as ISODateTime
  } else {
    // Return today's deadline if now is later than today's deadline
    return todaysDeadline.toISOString() as ISODateTime
  }
}

export function getAllAliases(
  aliases: Array<Record<string, string | number> | undefined>,
  options: {
    clientSideAliases?: boolean
  } = {}
): Record<string, string | number> {
  const authSnap = snapshot(authStore)
  const elplanSnap = snapshot(elplanStore)
  const activeSystem = authSnap.activeSystem

  const dynamicDefaultAliases: Record<string, string | number> = {
    system_id: activeSystem?.id || 0,
    system: activeSystem?.name || '',
    opt_model_id: activeSystem?.primary_opt_model?.id || 0,
    bid_deadline_time: getLatestBidDeadlineDateTime(elplanSnap.brokerDeadlineTime),
    currency: activeSystem?.currency?.toLocaleLowerCase() || 'sek',
    organization: activeSystem?.organization_name || '',
    el_bidding_area: activeSystem?.el_bidding_area_name || '',
  }

  let allAliases: Record<string, string | number> = {
    ...(options?.clientSideAliases !== false ? DEFAULT_CLIENT_SIDE_ALIASES : {}),
    ...DEFAULT_ALIASES,
    ...dynamicDefaultAliases,
  }

  aliases
    .filter((alias) => !!alias && isObject(alias))
    .forEach((alias) => {
      allAliases = {
        ...allAliases,
        ...alias,
      }
    })

  // Dynamic fall-back aliases if not already present
  if (!allAliases.optjob_type && allAliases.opt_job_type_id !== undefined) {
    allAliases.optjob_type = OPTJOB_TYPE_ID_TO_NAME[allAliases.opt_job_type_id as number] || ``
  }

  // Weather forecast
  if (allAliases.weather_forecast_locality && !allAliases.weather_forecast_organization) {
    allAliases.weather_forecast_organization = String(allAliases.weather_forecast_locality).replace('forecast_weather_', '').split('_')[0]
    allAliases.weather_forecast_system = String(allAliases.weather_forecast_locality).replace('forecast_weather_', '').split('_')[1]
    allAliases.weather_forecast_percentile = 50
  }

  // Heatload forecast
  if (allAliases.heatload_forecast_locality && !allAliases.heatload_forecast_organization) {
    allAliases.heatload_forecast_organization = String(allAliases.heatload_forecast_locality).replace('forecast_heat_', '').split('_')[0]
    allAliases.heatload_forecast_system = String(allAliases.heatload_forecast_locality).replace('forecast_heat_', '').split('_')[1]
    allAliases.heatload_forecast_percentile = 50
  }

  // Heatload outcome
  if (allAliases.meas_locality && !allAliases.heatload_organization) {
    const { organization, system } = extractOrganizationAndSystemFromLocality(allAliases.meas_locality as string)
    allAliases.heatload_organization = organization || allAliases.organization
    allAliases.heatload_system = system || allAliases.system
  } else if (!allAliases.heatload_organization) {
    allAliases.heatload_organization = allAliases.organization
    allAliases.heatload_system = allAliases.system
  }

  // Electriticy prices
  if (!allAliases.electricity_price_internal_id) {
    if (allAliases.electricity_price_locality) {
      const bidding_are = String(allAliases.electricity_price_locality).replace('forecast_electricity_price_', '').split('_')[0]
      allAliases.electricity_price_internal_id = `spot_price_${bidding_are}`
    } else {
      allAliases.electricity_price_internal_id = `spot_price_${activeSystem?.el_bidding_area_name}`
    }
  }

  return allAliases
}

export function generallyApplyAliases<T>(data: T, aliases?: Record<string, string | number>): T {
  const allAliases = getAllAliases([clone(aliases || {})])
  const parsedUiConfig = applyToNestedString<T>(data, (str: string): string | number => {
    if (str.startsWith(`$`)) {
      const key = str.substring(1, str.length)
      const alias = allAliases[key]

      if (alias === undefined) {
        return str
      }

      return alias
    }

    return str
  })

  return parsedUiConfig
}

export function applyAliases(uiConfig: UiConfig, baseAlias?: Record<string, string | number>): UiConfig {
  const allAliases = getAllAliases([uiConfig.alias, baseAlias])

  let parsedUiConfig = applyToNestedString<UiConfig>(uiConfig, (str: string): string | number => {
    const aliasPattern = /\{\$(.*?)\}([^{]*)?/g // Regular expression to match {$...} and optionally any characters that are not whitespace or a slash
    if (aliasPattern) {
      // Takes the $alias wrapped in {} and returns the alias and the rest of the string
      str = str.replace(aliasPattern, (match, key, rest = '') => {
        const alias = allAliases[key]
        return alias !== undefined ? alias + rest : match
      })
    }

    if (str.startsWith(`$`)) {
      const key = str.substring(1, str.length)
      const alias = allAliases[key]

      if (alias === undefined) {
        return str
      }

      return alias
    }

    if (str.startsWith(`__`)) {
      const key = str.substring(2, str.length)
      const translation = i18n.t(key)

      return translation ?? str
    }
    return str
  })

  parsedUiConfig = applyToNestedKeysThatStartWith(parsedUiConfig, '$', (str: string) => {
    const key = str.substring(1, str.length)
    const alias = allAliases[key]

    if (alias === undefined) {
      return str
    }

    return String(alias)
  })

  return parsedUiConfig
}

export function mergeUiConfigs(uiConfig: UiConfig, uiConfigOverriding: Partial<UiConfig>): UiConfig {
  return merge(clone(uiConfig), clone(uiConfigOverriding))
}


export function mergeWithBaseUiConfig(uiConfig: UiConfig, baseUiConfig: UiConfig | null): UiConfig {
  if (!baseUiConfig) {
    return uiConfig
  }

  // FIXME: Override-filter applies filter to all datasetinstructions, not only base items. It is an unwanted, since the variable name is "base_items_filter" and gives the impression that filter only applies to base_items.
  function overrideFilter(uiConfig: UiConfig): UiConfig {
    if (!uiConfig?.props?.base_items_filter) {
      return uiConfig
    }

    return {
      ...uiConfig,
      dataset_instructions: uiConfig.dataset_instructions.map((d) => ({
        ...d,
        filter: {
          ...(d.filter || {}),
          ...uiConfig.props.base_items_filter,
        },
      })),
    }
  }

  function prefixReturnIds<T>(data: T, prefix: string): T {
    if (!prefix) {
      return data
    }

    data = prefixReturnIdsForDbSDatasetInstruction(data, prefix)

    return applyToNestedStringForDictKeys(data, new Set(['return_id', 'data_id', 'variables']), (str) => `${prefix}${str}`)
  }

  const baseDatasetInstructions: Record<string, DatasetInstruction> = {}
  ;(baseUiConfig.props?.dataset_instructions as DatasetInstruction[])?.forEach((datasetInstruction) => {
    const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)

    returnIds.forEach((returnId) => {
      baseDatasetInstructions[returnId] = clone(datasetInstruction)
    })

  })
  // Template dataset instruction
  uiConfig.dataset_instructions = uiConfig.dataset_instructions.map((instruction) => {
    if (instruction.type !== `template`) {
      return instruction
    }

    const templateInstruction =
      baseDatasetInstructions[instruction.return_id] || DEFAULT_DATASET_TEMPLATES[instruction.return_id] || {}

    return merge(instruction, templateInstruction)
  })

  // Generate items & datasets based on "base_items"
  if (uiConfig.props?.base_items && baseUiConfig.props?.items) {
    const includeReturnIds: Set<string> = new Set()
    const excludeReturnIds: Set<string> = new Set()

    Object.entries(uiConfig.props.base_items || {}).forEach(([returnId, value]) => {
      if (value === false) {
        excludeReturnIds.add(returnId)
      } else if (returnId !== `all`) {
        includeReturnIds.add(returnId)
      }
    })

    Object.entries(uiConfig.props.include_only_base_items_with_attr || {}).forEach(([attr, value]) => {
      baseUiConfig.props.items?.forEach((item) => {
        if (item[attr] === value) {
          includeReturnIds.add(item.data_id)
        } else {
          excludeReturnIds.add(item.data_id)
        }
      })
    })

    let baseItems: UiConfigPropsItem[] = []
    if (uiConfig.props.base_items?.all === true) {
      baseItems = clone(baseUiConfig.props.items || [])
    } else {
      baseItems = clone(baseUiConfig.props.items?.filter((item) => includeReturnIds.has(item.data_id)) || [])
    }

    baseItems = baseItems.filter((item) => !excludeReturnIds.has(item.data_id))

    if (uiConfig.props.multi_base_items_override?.length) {
      uiConfig.props.multi_base_items_override.forEach((override) => {
        if (Object.keys(override.item || {}).length || override.title_suffix) {
          uiConfig.props.items = [
            ...(uiConfig.props.items || []),
            ...(baseItems || []).map((i) => {

              if (override.title_suffix) {
                const titleWithSuffix = `${i.title} ${override.title_suffix}`
                return prefixReturnIds(
                  {
                    ...i,
                    ...override.item,
                    title: titleWithSuffix,
                  },
                  override.return_id_prefix || ``
                )
              } else {
                return prefixReturnIds(
                  {
                    ...i,
                    ...override.item,
                  },
                  override.return_id_prefix || ``
                )
              }
            }
            ),
          ]
        }
        let baseUiConfigDatasetInstruction: DatasetInstruction[] = []

        if (override?.dataset_instruction?.contract) {
          const local = clone(baseUiConfig.dataset_instructions)

          baseUiConfigDatasetInstruction = local.map((d) => {
            return { ...d, contract: { ...d.contract, ...override.dataset_instruction?.contract } }
          })
        } else {
          baseUiConfigDatasetInstruction = clone(baseUiConfig.dataset_instructions)
        }

        const dataset_instructions = generallyApplyAliases(
          baseUiConfigDatasetInstruction,
          override.alias || {}
        )
        uiConfig.dataset_instructions.unshift(...prefixReturnIds(dataset_instructions, override.return_id_prefix || ``))
      })
    } else if (uiConfig.props.multi_base_item_alias_renaming?.length) {
      uiConfig.props.items = [...baseItems, ...(uiConfig.props?.items || [])]

      uiConfig.props.multi_base_item_alias_renaming.forEach((aliases) => {
        const dataset_instructions = generallyApplyAliases(clone(baseUiConfig.dataset_instructions), aliases)
        uiConfig.dataset_instructions.unshift(...dataset_instructions)
      })
    } else {
      uiConfig.props.items = [...baseItems, ...(uiConfig.props?.items || [])]

      uiConfig.dataset_instructions.unshift(...clone(baseUiConfig.dataset_instructions))
    }

    uiConfig = overrideFilter(uiConfig)
  }

  // Copy base tags_config from baseUiConfig and override with the tags_config of this uiConfig.
  uiConfig.props.tags_config = JSON.parse(JSON.stringify({...baseUiConfig.props.tags_config, ...uiConfig.props.tags_config}))

  return uiConfig
}

export function getAliasAndReturnIdPrefixLayers(uiConfig: UiConfig, baseUiConfig: UiConfig | null, overrideAlias: UiConfigAliases = {}): {alias: UiConfigAliases, returnIdPrefix: string}[] {
  // Combine uiConfig with baseUiConfig to create aliases and returnIdPrefixes used for dataset-fetch via uiconfig.
  if (!uiConfig) {
    return []
  }

  const layers: {alias: UiConfigAliases, returnIdPrefix: string}[] = []
  const allAliases = getAllAliases([
    baseUiConfig?.alias,
    baseUiConfig?.props?.alias || {},
    uiConfig.alias,
    overrideAlias,
  ], {
    clientSideAliases: false,
  })

  // Generate items & datasets based on "base_items"
  if (uiConfig?.props?.base_items && baseUiConfig?.props?.items) {
    if (uiConfig.props.multi_base_items_override?.length) {
      uiConfig.props.multi_base_items_override.forEach((override) => {
        layers.push({
          alias: {
            ...allAliases,
            ...(override.alias || {}),
          },
          returnIdPrefix: override.return_id_prefix || ``,
        })
      })
    } else if (uiConfig.props.multi_base_item_alias_renaming?.length) {
      uiConfig.props.multi_base_item_alias_renaming.forEach((aliases) => {
        layers.push({
          alias: {
            ...allAliases,
            ...aliases,
          },
          returnIdPrefix: ``,
        })
      })
    }
  }

  // Add default layer if no layers are defined
  if (layers.length === 0) {
    layers.push({
      alias: allAliases,
      returnIdPrefix: ``,
    })
  }

  // Apply aliases for alias depending on other alias
  layers.forEach((layer, index) => {
    layers[index].alias = generallyApplyAliases(layer.alias, layer.alias)
  })

  // Set start and end time if offset is only defined
  layers.forEach((layer, index) => {
    if (!layer.alias.start_time && (layer.alias.offset_start_time !== null && layer.alias.offset_start_time !== undefined)) {
      layers[index].alias.start_time = Datetime.getISONow(Number(layer.alias.offset_start_time))
    }

    if (!layer.alias.end_time && (layer.alias.offset_end_time !== null && layer.alias.offset_end_time !== undefined)) {
      layers[index].alias.end_time = Datetime.getISONow(Number(layer.alias.offset_end_time))
    }
  })

  return layers
}

function translateUiConfigField(text: string): string {
  if (text.startsWith(`__`)) {
    return i18n.t(text.split(`__`)[1])
  }

  // Translate interpolated strings. Example: "Heat {{system=Demostaden}}" === "Värme Demostaden". Note that you need to add "Heat {{system}}" in source.json and translate it in Transifex
  const interpolatedString = text.match(/\{\{(.*?)\}\}/g)
  if (interpolatedString) {
    const interpolationParameters: { [key: string]: string } = {}

    interpolatedString.forEach((str) => {
      let key = str.substring(2, str.length - 2)
      if (key.startsWith(`__`)) {
        key = key.substring(2)
      }
      const parameterKey = key.split(`=`)[0]
      const parameterValue = key.split(`=`)[1]
      interpolationParameters[parameterKey] = parameterValue

      text = text.replace(str, `{{${parameterKey}}}`)
    })
    return i18n.t(text, interpolationParameters)
  }
  return i18n.t(text)
}

export function translatedUiConfig(uiConfig: UiConfig): UiConfig {
  return applyToNestedStringForDictKeys(uiConfig, new Set(['title', 'tooltip' ]), translateUiConfigField) as UiConfig
}

