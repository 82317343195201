import { OPTJOB_TYPE_ID_TO_NAME } from 'ui/uiConfig/template/aliases'

import { clone, isArray, isObject } from 'helpers/global.helper/global.helper'

export function prefixReturnIdsForDbSDatasetInstruction<T>(data: T, prefix: string): T {
  if (!prefix) {
    return data
  }

  if (isArray(data)) {
    return (data as any[]).map((item) => prefixReturnIdsForDbSDatasetInstruction(item, prefix)) as T
  }

  if (!isObject(data)) {
    return data
  }

  Object.keys(data).forEach((key) => {
    if (key === 'id_renaming') {
      let internalIds = (data as DatasetContractDbS)?.metadata_filter?.include?.internal_id || []
      if (typeof internalIds === 'string') {
        internalIds = [internalIds]
      }

      const uniqueInternalIds = Array.from(new Set(internalIds))

      const idRenaming = (data as DatasetContractDbS)?.id_renaming || {}
      uniqueInternalIds.forEach((internalId) => {
        let returnId = idRenaming[internalId] || internalId

        if (isArray(returnId)) {
          returnId = returnId.map((id) => `${prefix}${id}`)
          idRenaming[internalId] = returnId
        } else {
          returnId = `${prefix}${returnId}`
          idRenaming[internalId] = returnId
        }
      })

      data[key] = idRenaming
    } else {
      data[key] = prefixReturnIdsForDbSDatasetInstruction(data[key], prefix)
    }
  })

  return data
}

export function serializeMetadataFilter(metadataFilter: { include: Record<string, string[] | string>, exclude: Record<string, string[] | string> }): { include: Record<string, string[]>, exclude: Record<string, string[]> } {
  const include = metadataFilter.include || {}
  const exclude = metadataFilter.exclude || {}

  // Make sure that all values are arrays and remove any empty arrays or values
  Object.entries(include).forEach(([key, values]) => {
    if (!isArray(values)) {
      values = [values as string]
      include[key] = values
    }

    include[key] = (values as string[])
      .filter((value) => value !== '' && value !== null && value !== undefined)
      .map(value => String(value))

    const nrOfValues = include[key].length
    const uniqueValues = Array.from(new Set(include[key]))
    if (nrOfValues !== uniqueValues.length) {
      const duplicateValues = include[key].filter((value, index) => include[key].indexOf(value) !== index)
      console.warn(`DbS metadataFilter.include.${key} contains duplicate values: `, { metadataFilter, duplicateValues })
      include[key] = uniqueValues
    }
  })
  Object.entries(exclude).forEach(([key, values]) => {
    if (!isArray(values)) {
      exclude[key] = [values as string]
    }

    exclude[key] = (values as string[])
      .filter((value) => value !== '' && value !== null && value !== undefined)
      .map(value => String(value))

    const nrOfValues = exclude[key].length
    const uniqueValues = Array.from(new Set(exclude[key]))
    if (nrOfValues !== uniqueValues.length) {
      const duplicateValues = exclude[key].filter((value, index) => exclude[key].indexOf(value) !== index)
      console.warn(`DbS metadataFilter.exclude.${key} contains duplicate values: `, { metadataFilter, duplicateValues })
      exclude[key] = uniqueValues
    }
  })

  return { include, exclude } as { include: Record<string, string[]>, exclude: Record<string, string[]> }
}

// Migrate Ui Config to DbS
const ENABLE_SOFT_MIGRATE_UI_CONFIGS = localStorage.getItem('ENABLE_SOFT_MIGRATE_UI_CONFIGS') === 'true' || (window.location?.search || '').indexOf('enable_soft_migration_ui_configs') !== -1

if (ENABLE_SOFT_MIGRATE_UI_CONFIGS) {
  console.warn('Soft migrating UI configs to DbS')
}

export function softMigrateUiConfigsToDbS<T extends UiConfig | UiConfig[]>(uiConfigs: T): T {
  // [2024-09-10 Mattias]: Feature flag for testing DbS dataset instructions without saving migrated ui configs to db. Remove after testing.

  if (!ENABLE_SOFT_MIGRATE_UI_CONFIGS) {
    return uiConfigs
  }

  if (isArray(uiConfigs)) {
    return (uiConfigs as UiConfig[]).map(u => {
      try {
        return migrateUiConfigToDbS(u) as UiConfig
      } catch (_e) {
        return u
      }
    }) as T
  }

  try {
    return migrateUiConfigToDbS(uiConfigs as UiConfig) as T
  } catch (_e) {
    return uiConfigs
  }
}

export function canMigrateUiConfigToDbS(uiConfig: UiConfig): boolean {
  const originalHash = JSON.stringify(uiConfig)
  try {
    const migratedHash = JSON.stringify(migrateUiConfigToDbS(uiConfig))

    return originalHash !== migratedHash
  } catch (_: any) {
    console.log(`DbS-migration will throw error if executed`, _)
    return false
  }
}

export function migrateUiConfigToDbS(uiConfig: UiConfig): UiConfig {
  let migratedUiConfig = clone(uiConfig)

  // Migrate dataset instructions
  if (uiConfig?.dataset_instructions?.length) {
    migratedUiConfig.dataset_instructions = migratedUiConfig.dataset_instructions.map((datasetInstruction) => {
      return migrateDatasetInstructionToDbS(datasetInstruction)
    }).flat()

    // Base items template dataset instructions
    if (migratedUiConfig.props?.dataset_instructions?.length) {
      migratedUiConfig.props.dataset_instructions = migratedUiConfig.props.dataset_instructions.map((datasetInstruction) => {
        return migrateDatasetInstructionToDbS(datasetInstruction)
      }).flat()
    }
  }

  // Migrate props
  migratedUiConfig = migrateAliases(migratedUiConfig)

  return migratedUiConfig
}


// Dataset Instruction migration
export function convertOptJobTypeIdToName(optJobTypeId: number | string): string {
  if (typeof optJobTypeId === 'number') {
    return OPTJOB_TYPE_ID_TO_NAME[optJobTypeId]
  } else if (OPTJOB_TYPE_ID_TO_NAME[optJobTypeId] !== undefined) {
    return OPTJOB_TYPE_ID_TO_NAME[optJobTypeId]
  }

  if (optJobTypeId === '$opt_job_type_id') {
    return '$optjob_type'
  }

  if (optJobTypeId.endsWith('opt_job_type_id')) {
    return optJobTypeId.replace('opt_job_type_id', 'optjob_type')
  }

  throw new Error(`Can not convert opt job type id to name: ${optJobTypeId}`)
}

export function migrateDatasetInstructionToDbS(datasetInstruction: DatasetInstruction): DatasetInstruction[] {
  if (datasetInstruction?.filter?.created_at === undefined) {
    if (!datasetInstruction.filter) {
      datasetInstruction.filter = {}
    }

    datasetInstruction.filter.created_at = '$created_at'
  }


  if (datasetInstruction.type === 'dbs') {
    return [datasetInstruction]
  }

  if (datasetInstruction.type === 'calc') {
    return [datasetInstruction]
  }

  if (datasetInstruction.type === 'template') {
    return [datasetInstruction]
  }

  // TODO: Enable wasteheat_collaboration_export to DbS when data is saved
  if (datasetInstruction.type === 'optresults'
  // || datasetInstruction.type === '$optresult_api_endpoint' || datasetInstruction.type === 'custom/wasteheat_collaboration_export'
  ) {
    return migrateOptresultDatasetInstruction(datasetInstruction)
  }

  if (datasetInstruction.type === 'meas') {
    // Electricity
    if (datasetInstruction.contract?.locality?.startsWith('forecast_electricity_')) {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: migrateElectricityMeasContract(datasetInstruction.contract, datasetInstruction.return_id),
        return_id: '',
      }]
    }

    // VF Electricity
    if (datasetInstruction.type === 'meas' && datasetInstruction.contract?.tag === 'elspotforecast_vf') {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: {
          global_read_api: true,
          id_renaming: {
            elspotforecast_vf: datasetInstruction.return_id,
          },
          metadata_filter: {
            include: {
              internal_id: ['elspotforecast_vf'],
              organization: ['vf'],
              system: ['vf'],
              abs_service: ['abs_meas'],
            },
            exclude: {},
          },
        },
        return_id: '',
      }]
    }
    if (datasetInstruction.type === 'meas' && datasetInstruction.contract?.tag === 'elcertprice_vf') {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: {
          global_read_api: true,
          id_renaming: {
            elcertprice_vf: datasetInstruction.return_id,
          },
          metadata_filter: {
            include: {
              internal_id: ['elcertprice_vf'],
              organization: ['vf'],
              system: ['vf'],
              abs_service: ['abs_meas'],
            },
            exclude: {},
          },
        },
        return_id: '',
      }]
    }
    if (datasetInstruction.type === 'meas' && datasetInstruction.contract?.tag === 'euets_vf') {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: {
          global_read_api: true,
          id_renaming: {
            euets_vf: datasetInstruction.return_id,
          },
          metadata_filter: {
            include: {
              internal_id: ['euets_vf'],
              organization: ['vf'],
              system: ['vf'],
              abs_service: ['abs_meas'],
            },
            exclude: {},
          },
        },
        return_id: '',
      }]
    }

    // Weather
    if (datasetInstruction.contract?.locality?.startsWith('forecast_weather_')) {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: migrateWeatherMeasContract(datasetInstruction.contract, datasetInstruction.return_id),
        return_id: '',
      }]
    }

    // Forecast
    if (datasetInstruction.contract?.locality?.startsWith('forecast_')) {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: migrateForecastMeasContract(datasetInstruction.contract, datasetInstruction.return_id),
        return_id: '',
      }]
    }

    // Meas
    if (datasetInstruction.contract?.locality?.startsWith('meas_') || datasetInstruction.contract?.locality?.startsWith('plant_')) {
      return [{
        ...datasetInstruction,
        type: 'dbs',
        contract: migrateMeasContract(datasetInstruction.contract, datasetInstruction.return_id),
        return_id: '',
      }]
    }

    // Arbitrary meas-like dataset
    return migrateUnknownMeasDatasetInstruction(datasetInstruction)
  }

  console.log(`Not implemented migration for dataset instruction type: ${datasetInstruction.type}`)
  return [datasetInstruction]
}

export function migrateOptresultDatasetInstruction(datasetInstruction: DatasetInstruction): DatasetInstruction[] {
  const contract = datasetInstruction.contract as DatasetContractOptResults
  const internalIds: string[] = []
  const idRenaming: Record<string, string | string[]> = {}
  const optJobType = convertOptJobTypeIdToName(contract.opt_job_type_id)
  const uniqueSources: Set<string> = new Set()
  const potentialIdRenaming: Record<string, string> = {}
  const duplicateSourcesToRename: Record<string, string[]> = {}

  contract.sources.forEach(({ source, return_id }) => {
    if (uniqueSources.has(source)) {
      if (!duplicateSourcesToRename[source]) {
        duplicateSourcesToRename[source] = [potentialIdRenaming[source]]
      }

      duplicateSourcesToRename[source].push(return_id)
    } else {
      uniqueSources.add(source)
      potentialIdRenaming[source] = return_id
    }

    internalIds.push(source)

    if (source !== return_id) {
      idRenaming[source] = return_id
    }
  })

  // Re-format duplicate sources in idRenaming
  if (Object.keys(duplicateSourcesToRename).length > 0) {
    Object.entries(duplicateSourcesToRename).forEach(([source, returnIds]) => {
      idRenaming[source] = returnIds
    })
  }

  const include: Record<string, string[]> = {
    organization: ['$organization'],
    system: ['$system'],
    abs_service: ['abs_optservice'],
    optjob_type: [optJobType],
    internal_id: internalIds,
  }

  if (contract.subtype) {
    include.optjob_subtype = [contract.subtype]
  }

  if (contract.opt_time) {
    datasetInstruction.filter.created_at = contract.opt_time
  }

  const metadataFilter = serializeMetadataFilter({ include, exclude: {} })

  return [{
    ...datasetInstruction,
    type: 'dbs',
    contract: {
      global_read_api: false,
      id_renaming: idRenaming,
      metadata_filter: metadataFilter,
    },
    return_id: '',
  }]
}

export function migrateForecastMeasContract(contract: DatasetContractMeas, returnId: string): DatasetContractDbS {
  const { organization, system } = extractOrganizationAndSystemFromLocality(contract.locality)
  const internalId = contract.locality.replace('forecast_', '').replace(`_${organization}_${system}`, '')

  const idRenaming: Record<string, string> = {}
  if (internalId !== returnId) {
    idRenaming[internalId] = returnId
  }

  return {
    global_read_api: true,
    id_renaming: idRenaming,
    metadata_filter: {
      include: {
        internal_id: [internalId],
        organization: [organization],
        system: [system],
        abs_service: ['abs_forecast'],
        percentile: ['50'],
      },
      exclude: {},
    },
  }
}

export function migrateWeatherMeasContract(contract: DatasetContractMeas, returnId: string): DatasetContractDbS {
  const { organization, system } = extractOrganizationAndSystemFromLocality(contract.locality)
  const internalId = contract.tag

  const idRenaming: Record<string, string> = {}
  if (internalId !== returnId) {
    idRenaming[internalId] = returnId
  }

  return {
    global_read_api: true,
    id_renaming: idRenaming,
    metadata_filter: {
      include: {
        internal_id: [internalId],
        organization: [organization],
        system: [system],
        abs_service: ['abs_weather'],
        percentile: ['50'],
      },
      exclude: {},
    },
  }
}

export function migrateMeasContract(contract: DatasetContractMeas, returnId: string): DatasetContractDbS {
  const internalIds: string[] = []
  const idRenaming: Record<string, string> = {}
  let organization = '$organization'
  let system = '$system'

  if (contract.sources) {
    contract.sources.forEach(({ source, return_id }) => {
      internalIds.push(source)

      if (source !== return_id) {
        idRenaming[source] = return_id
      }
    })
  } else {
    internalIds.push(contract.tag)

    if (contract.tag !== returnId) {
      idRenaming[contract.tag] = returnId
    }
  }

  if (contract.locality.startsWith('meas_')) {
    const os = extractOrganizationAndSystemFromLocality(contract.locality)
    organization = os.organization
    system = os.system
  }

  const metadataFilter = serializeMetadataFilter({
    include: {
      internal_id: internalIds,
      organization: [organization],
      system: [system],
      abs_service: ['abs_meas'],
    },
    exclude: {},
  })

  return {
    global_read_api: true,
    id_renaming: idRenaming,
    metadata_filter: metadataFilter,
  }
}

export function migrateElectricityMeasContract(contract: DatasetContractMeas, returnId: string): DatasetContractDbS {
  const el_bidding_area = contract.locality.replace('forecast_electricity_price_', '')
  const internalId = `spot_price_${el_bidding_area}`
  const idRenaming: Record<string, string> = {}

  if (internalId !== returnId) {
    idRenaming[internalId] = returnId
  }

  // Outcome
  if (contract.tag === 'spot') {
    return {
      global_read_api: true,
      id_renaming: idRenaming,
      metadata_filter: {
        include: {
          internal_id: [internalId],
          organization: ['global'],
          abs_service: ['abs_electricity_price'],
          data_source: ['nordpool'],
          currency: ['$currency'],
          dataset_type: ['measurement'],
        },
        exclude: {},
      },
    }
  }

  // Outcome & extrapolated by forecast
  return {
    global_read_api: true,
    id_renaming: idRenaming,
    metadata_filter: {
      include: {
        internal_id: [internalId],
        organization: ['global'],
        abs_service: ['abs_electricity_price'],
        data_source: ['nordpool_abs'],
        dataset_type: ['calculation'],
      },
      exclude: {},
    },
  }
}

export function migrateUnknownMeasDatasetInstruction(originalDatasetInstruction: DatasetInstruction): DatasetInstruction[] {
  const internalIds: string[] = []
  const idRenaming: Record<string, string> = {}
  const organization = '$organization'
  const system = '$system'
  const contract = originalDatasetInstruction.contract as DatasetContractMeas
  const returnId = originalDatasetInstruction.return_id

  const dsiSplitUpFromSources: DatasetInstruction[] = []

  if (contract.sources) {
    const localities = Array.from(new Set(contract.sources.filter(s => !!s.locality).map(({ locality }) => locality)))

    if (localities.length > 0) {
      // Multiple localities, must split up into multiple dataset instructions
      contract.sources.forEach((sourceItem) => {

        // Split up into multiple meas DSI
        const orgDSIChild = clone(originalDatasetInstruction)
        orgDSIChild.contract = {
          locality: sourceItem.locality || originalDatasetInstruction.contract.locality,
          tag: sourceItem.source,
          entry_identifier_prefix: sourceItem.entry_identifier_prefix ?? originalDatasetInstruction.contract.entry_identifier_prefix ?? '',
        } satisfies DatasetContractMeas
        orgDSIChild.return_id = sourceItem.return_id || originalDatasetInstruction.return_id

        migrateDatasetInstructionToDbS(orgDSIChild).forEach(dsi => {
          dsiSplitUpFromSources.push(dsi)
        })
      })

      return dsiSplitUpFromSources
    } else {
      // There are not multiple localities, can combine into one dataset instruction
      contract.sources.forEach((sourceItem) => {
        const source = sourceItem.source
        const returnId = sourceItem.return_id

        internalIds.push(source)

        if (source !== returnId) {
          idRenaming[source] = returnId
        }
      })
    }
  } else {
    internalIds.push(contract.tag)

    if (contract.tag !== returnId) {
      idRenaming[contract.tag] = returnId
    }
  }

  // Common use cases
  if (contract.locality === '$meas_locality') {
    return [{
      type: 'dbs',
      filter: originalDatasetInstruction.filter,
      contract: {
        global_read_api: true,
        id_renaming: idRenaming,
        metadata_filter: {
          include: {
            internal_id: internalIds,
            organization: [organization],
            system: [system],
            abs_service: ['abs_meas'],
          },
          exclude: {},
        },
      },
      return_id: '',
    }]
  }

  console.log(originalDatasetInstruction)
  throw new Error(`Can not migrate unknown meas contract with locality "${contract.locality}"`)
}

export function extractOrganizationAndSystemFromLocality(locality: string): { organization: string | undefined; system: string | undefined } {
  const parts = locality.split('_')

  if (parts.length <= 2) {
    return {
      organization: undefined,
      system: undefined,
    }
  }

  const organization = parts[parts.length - 2]
  const system = parts[parts.length - 1]

  return { organization, system }
}

export function extractMetadaIncludeFilterFromLocality(locality: string): Record<string, string[]> {
  const parts = locality.split('_')

  if (parts.length < 3) {
    throw new Error(`Can not extract metadata include filter from locality: ${locality}`)
  }

  const organization = parts[parts.length - 2]
  const system = parts[parts.length - 1]

  const metadataIncludeFilter: Record<string, string[]> = {
    organization: [organization],
    system: [system],
  }

  if (locality.startsWith('forecast_electricity_price_')) {
    metadataIncludeFilter.abs_service = ['abs_electricity_price']
    metadataIncludeFilter.organization = ['global']
    delete metadataIncludeFilter.system

    // forecast_electricity_price_se3 => internal_id=spot_price_se3
    metadataIncludeFilter.internal_id = [
      `spot_price_${locality.replace('forecast_electricity_price_', '')}`,
    ]

  } else if (locality.startsWith('meas_')) {
    metadataIncludeFilter.abs_service = ['abs_meas']

  } else if (locality.startsWith('forecast_')) {
    metadataIncludeFilter.abs_service = ['abs_forecast']

    // forecast_heat_sigholm_demostaden => internal_id=heat
    metadataIncludeFilter.internal_id = [
      locality
        .replace('forecast_', '')
        .replace(`_${organization}_${system}`, ''),
    ]
  } else {
    throw new Error(`Can not extract metadata include filter from locality: ${locality}`)
  }

  return metadataIncludeFilter
}


// Ui Config Props migration
export function migrateAliases(uiConfig: UiConfig): UiConfig {
  const setDeepValue = <T>(obj: T, path: Array<string | number>, value: any): T => {
    if (path.length === 0) {
      return obj
    }

    const [first, ...rest] = path

    if (rest.length === 0) {
      if (Array.isArray(obj) && typeof first === 'number') {
        obj[first] = value
      } else if (typeof obj === 'object' && obj !== null) {
        (obj as any)[first] = value
      }
      return obj
    }

    if (Array.isArray(obj) && typeof first === 'number') {
      obj[first] = setDeepValue(obj[first], rest, value)
    } else if (typeof obj === 'object' && obj !== null) {
      (obj as any)[first] = setDeepValue((obj as any)[first], rest, value)
    }

    return obj
  }

  const migrateDeepAlias = <T>(obj: T, path: Array<string | number>): T => {
    if (isObject(obj)) {
      for (const key of Object.keys(obj)) {
        const newPath = [...path, key]
        migrateDeepAlias(obj[key], newPath)
      }
    } else if (isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const newPath = [...path, i]
        migrateDeepAlias(obj[i], newPath)
      }

    } else if (typeof obj === 'string' || typeof obj === 'number') {
      const key = path[path.length - 1]
      const value = obj as string

      if (typeof key === 'string') {
        if (key.indexOf('opt_job_type_id') !== -1) {
          const newKey = key.replace('opt_job_type_id', 'optjob_type')
          let newValue = OPTJOB_TYPE_ID_TO_NAME[value]

          if (newValue === undefined) {
            newValue = value.replace('opt_job_type_id', 'optjob_type')
          }

          const newPath = [...path]
          newPath.pop()
          newPath.push(newKey)

          uiConfig = setDeepValue(uiConfig, newPath, newValue)
        }
      }
    }
  }

  migrateDeepAlias(uiConfig, [])

  return uiConfig
}