import { useCallback } from 'react'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import { DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP, DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE, reFetchAllDatasets, reFetchAllDatasetsOnFuelPlan } from 'api/dataset/dataset.api'
import { OptJobStatus, OptJobType, OPT_JOBS_QUERY_KEY, setOptJobLatestQueryData } from 'api/optJobs/optJobs.api'
import { OPT_PROJECTS_QUERY_KEY, setOptProjectsQueryData } from 'api/optProjects/optProjects.api'
import { SERIES_GROUP_DATA_QUERY_KEY } from 'api/seriesGroups/seriesGroups.api'
import { SYSTEM_QUERY_KEY } from 'api/systems/systems.api'
import { UNITS_QUERY_KEY } from 'api/units/units.api'
import { useUser } from 'api/users/users.api'
import { useTranslation } from 'react-i18next'
import authStore from 'store/auth/auth'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { SubtypeNotification } from 'ui/components/NotificationCenter/NotificationCenter.helper'

import { queryClient } from 'helpers/queryClient'

const alertProps = {
  anchorOrigin: { vertical: `top` as const, horizontal: `right` as const },
  autoHideDuration: 6000,
}
const onOptModelHasUnusedSettingsPusherEvent = (
  data:
    | {
        opt_model_id: number
        system: number
      }
    | undefined
): void => {
  if (data) {
    queryClient.invalidateQueries([SYSTEM_QUERY_KEY, data.system])
  }
}

export default function Initialization(): null {
  const { activeSystem, setActiveSystem } = useAuth()
  const { data: user } = useUser()
  const { info, success, error } = useAlert()
  const { t } = useTranslation()

  const optimizationsChannel = useChannel(activeSystem ? `aurora-django-optimizations-${activeSystem.name}` : undefined)
  useEvent(optimizationsChannel, 'opt-model-has-unused-settings', onOptModelHasUnusedSettingsPusherEvent)

  const systemChannel = useChannel(activeSystem ? `aurora-django-system-${activeSystem.id}` : undefined)
  useEvent(systemChannel, 'primary_model_updated', (data?: {primary_opt_model?: PrimaryOptModel, primary_digital_twin?: PrimaryDigitalTwin}) => {
    if (activeSystem && data) {
      const newSystem = activeSystem
      newSystem.primary_opt_model = data?.primary_opt_model
      newSystem.primary_digital_twin = data?.primary_digital_twin
      setActiveSystem(newSystem)
      authStore.activeSystem = newSystem
    }
  })

  const handleAutomaticOptimizationPusherEvent = useCallback(
    (data: { system: number; status: boolean } | undefined): void => {
      if (data) {
        queryClient.setQueryData<System | undefined>([SYSTEM_QUERY_KEY, data.system], (old) => {
          if (old) {
            data.status
              ? info(t(`Automatic optimization activated! Optimizations will be updated once every hour.`))
              : info(
                t(
                  `Automatic optimization deactivated! Optimizations will now need to be executed manually in order for the production plan to update.`
                )
              )
            return {
              ...old,
              automatic_optimization: data.status,
            }
          }
          return old
        })
      }
    },
    [info]
  )
  useEvent(optimizationsChannel, `automatic-opt-state-changed`, handleAutomaticOptimizationPusherEvent)

  const handleAccumulatorLevelsUpdated = useCallback(
    (data: { organization: number; system: number; opt_model: number } | undefined): void => {
      if (data) {
        const { opt_model } = data
        queryClient.invalidateQueries([UNITS_QUERY_KEY, { optModel: opt_model, unit_type: `Storage` }])
        info(t(`Measured values have been updated`), alertProps)
      }
    },
    [info]
  )
  useEvent(optimizationsChannel, `ack-levels-updated`, handleAccumulatorLevelsUpdated)

  const optimizationUpdatedPusherEvent = useCallback(
    (
      data:
        | {
            organization: number
            opt_job_type: OptJobType
            system: number
            opt_model: number
            status: OptJobStatus
            created_by: number
            opt_project: number
            subtypes?: SubtypeNotification[]
          }
        | undefined
    ): void => {
      if (data) {
        const { opt_model, opt_project, opt_job_type: type, status, created_by, system } = data
        setOptJobLatestQueryData({ optModelId: opt_model, systemId: system, type, data: { status } })

        if (opt_project) {
          if (status === `Finished`) {
            setOptJobLatestQueryData({ optModelId: opt_model, systemId: system, type, status, data: { status } })
            queryClient.invalidateQueries(OPT_PROJECTS_QUERY_KEY)
          } else setOptProjectsQueryData({ projectId: opt_project, system, status })
        }

        if (type === `measvalues_calculations` || type === `regular` || type === 'fuel_plan') {
          if (status === `Finished`) {
            if (type === 'fuel_plan') {
              reFetchAllDatasetsOnFuelPlan()
            } else {
              reFetchAllDatasets()
            }
            // To get new data
            queryClient.invalidateQueries([
              SERIES_GROUP_DATA_QUERY_KEY,
              {
                groupType: `PROD_REG`,
              },
            ])
          }
          if (type === `regular` || type === 'fuel_plan') {
            if (status !== undefined && [`Finished`, `Failed`, `Unsolvable`, `Created`, `Queued`, `Running`].includes(status)) {
              // To update text below optimize button
              queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, `latest`, { type }])
            }

            if (status === `Finished`) {
              // To reset opt_model_has_unused_settings
              queryClient.invalidateQueries([SYSTEM_QUERY_KEY, data.system])
              if (data.subtypes) {
                const subtypeNames = data.subtypes.map(subtype => subtype.display_name).join(' ,')
                success(t(`Production plan optimization finished for subtypes: {{subtypeNames}}`, {subtypeNames: subtypeNames}), alertProps)
              }
              if (user && created_by !== user.id) {
                success(t(`Production plan optimization finished! The new result is presented automatically`), alertProps)
              } else if (created_by === null) {
                success(t(`An automatic optimization finished just now`), alertProps)
              } else {
                success(t(`Optimization successful`), alertProps)
              }
            } else if (status === `Failed`) {
              error(t(`Optimization failed`), alertProps)
              if (data.subtypes) {
                const subtypeNames = data.subtypes.map(subtype => subtype.display_name).join(' ,')
                error(t(`Production plan optimization failed for subtypes: {{subtypeNames}}`, {subtypeNames: subtypeNames}), alertProps)
              }
            } else if (status === `Unsolvable`) {
              error(t(`No optimal solution could be found. Check current model settings and try again.`), alertProps)
            }
          }
        } else if (type === `sandbox` && created_by === user?.id) {
          if (status === `Finished`) {
            success(t(`Sandbox optimization successful`), alertProps)
          } else if (status === `Failed`) {
            error(t(`Sandbox optimization failed`), alertProps)
          } else if (status === `Unsolvable`) {
            error(
              t(`No optimal solution could be found in sandbox optimization. Check your settings and try again.`),
              alertProps
            )
          }
        } else if (type === 'real_time') {
          if (status === 'Finished') {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE])
          }
        } else if (type === 'real_time_measvalues_calculations') {
          if (status === 'Finished') {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE])
          }
        } else if (type === 'followup_without_deviations') {
          if (status === `Finished`) {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP])
            success(t(`Followup optimization successful`), alertProps)
            if (data.subtypes) {
              const subtypeNames = data.subtypes.map(subtype => subtype.display_name).join(' ,')
              success(t(`Followup optimization finished for subtypes: {{subtypeNames}}`, {subtypeNames: subtypeNames}), alertProps)
            }
          } else if (status === `Failed`) {
            error(t(`Followup optimization failed`), alertProps)
            if (data.subtypes) {
              const subtypeNames = data.subtypes.map(subtype => subtype.display_name).join(' ,')
              error(t(`Followup optimization failed for subtypes: {{subtypeNames}}`, {subtypeNames: subtypeNames}), alertProps)
            }
          } else if (status === `Unsolvable`) {
            error(t(`No optimal solution could be found. Check current model settings and try again.`), alertProps)
          }
        }
      }
    },
    [error, info, success, user]
  )
  useEvent(optimizationsChannel, `optimization-updated`, optimizationUpdatedPusherEvent)

  return null
}
